.missiondescription h6{
    border-left: 8px solid rgba(21, 36, 67, 1);
    padding-left: 15px;
    text-indent: 50px;
    text-align: justify;
    padding:50px 20px;
}
.qauidmissionimage img{
    width: 100%;
    height: 10% !important;
}
.qauidmissionspe img{

    width: 60%;
    margin: auto;
    display: block;
}
.styledborder{
    margin-top: 10px;
    border: 3px double #ffc33c;
    padding: 10px;
}
@media only screen and (max-width:320px) {
    .missiondescription h6{
        padding: 20px 5px;
    }
    .poetry p{
        font-size: 12px !important;
    }
}
@media only screen and (max-width:767px) and (min-width:321px) {
    .missiondescription h6{
        padding: 20px 5px;
    }
    .poetry p{
        font-size: 16px !important;
    }
}
@media only screen and (max-width:1199px) and (min-width:991px)  {
    .missiondescription h6{
        padding: 20px 5px;
    }
    .poetry p{
        font-size: 22px !important;
    }
}
@media only screen and (max-width:991px) and (min-width:768px)  {
    .missiondescription h6{
        padding: 20px 5px;
    }
    .poetry p{
        font-size: 15px !important;
    }
}