.main_div_footer{
    background-color: #152443;
    color: white;
}
.footer_links{
    border-left: 3px solid #be6257;
    padding-left: -20px !important;
}
.footer_links li a{
    color: white;
    /* border: 1px solid red; */
    padding: 3px 0;
    margin:0 -20px !important;
}
.footer_links li{
    color: white;
    list-style: none;
    margin: 5px 0;
}
.footerWeb{
    color:  #be6257;
    text-decoration: none;
}
.copyrightdiv{
    background-color:#39455f    ;
    color: white;
    padding: 10px;
}
.copyright_des p{
    font-size: 18px;
}
.footer_social_links{
    display: flex;
    width: 100%;
    border-left: 3px solid #be6257;
    padding-left: -20px;
}
.footer_social_links li {
    list-style: none;

}
.footer_social_links li a:hover{
    background-color: white;
}
.footer_social_links li a{
    text-decoration: none; 
    border-radius: 50%;
    border: 1px solid white;
    padding: 6px 5px 8px 5px    ;
    margin: 6px 5px 8px 5px ;
}
.facebook{
    color: blue !important;
    background-color: white;
}
.twitter{
    color: rgb(0, 177, 247) !important;
    background-color: white;
}
.youtube{
    color: red !important;
    background-color: white;
}
.instagram{
    color: rgb(237, 122, 46) !important;
    background-color: white;
}
.footer_quick_links{
    border-left: 3px solid #be6257;
}
.footer_quick_links li{
    list-style: none;
}
.footer_quick_links li a{
    text-decoration: none;
    color: white;
}
.footer_quick_links li a:hover{
    color: #be6257;
}
.footer_links li a:hover {
    color: #be6257 !important;
}
.footerimg{
    width: 100px;
    display: inline;
}
.logotext{
    display: inline-block;
    position: absolute;
    border-left: 3px solid #be6257;
    margin: 20px 15px;
    padding: 10px;
}
.logotext h5{
    font-size: 18px !important;
}
@media screen and (max-width:500px) {
    .logotext h5{
        font-size: 16px;
        margin-top: 5px;
    } 
    .footerimg{
        width: 100px;   
    }
}
@media screen and (max-width:380px) {
    .logotext h5{
        font-size: 12px !important;
        margin-top: 0px !important;
    } 
    .footerimg{
        width: 80px !important;   
    }
}
@media screen and (max-width:1200px) {
    .logotext h5{
        font-size: 16px;
        margin-top: 5px;
    } 
    .footerimg{
        width: 100px;   
    }
}