#main_carousel{
    position: relative;
    height: 70vh;
}
.slider-btn button{
    border: none;
    /* background-color: #152443; */
}
.carouse-height{
    height: 70vh;
}
.slider-btn .nexti,
.slider-btn .previ{
    position: absolute;
    top: 20%;
    width: 70px;
    height: 150px;
    line-height: 50px;
    background: transparent;
    border-radius: 10px;
    transition: 0.5s;
    z-index: 5;
    font-size: 24px !important;
    transition: 0.5s;
    /* color: #fff; */
}

.slider-btn .nexti{
    right: 20px;
}
.slider-btn .previ{
    left: 20px;
}
.slider-btn .nexti:hover,
.slider-btn .previ:hover{
    background-color: rgba(193, 193, 193,0.5);
    color: #fff;
    cursor: pointer;
}

.main_div_slider{
    position: relative;
    height: 70vh;
}
.main_div_slider .slider_main_text{
    position: absolute;
    left: 50%;
    bottom: 10%;
    top: 70%;

    transform: translate(-50%,0);
}
.main_div_slider .slider_main_text h3{
    left: 50%;
    color: white;
}
@media screen and (max-width:500px) {
    #main_carousel{
        position: relative;
        height: 50vh;
    }
    .carouse-height{
        height: 50vh;
    }
}