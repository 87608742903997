.subheading{
    background-image: url('../../public/Images/subheading.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* height: 10%; */
    display: block;
    color: white;
    padding: 5px 20px;
    margin:0 auto !important;
}
.subheading h2{
    margin: 15px 5px;
}

@media screen and (max-width:320px) {
    .subheading h2{
        font-size: 12px !important;
    }
}
@media screen and (max-width:321px) and (min-width:560px) {
    .subheading h2{
        font-size: 14px !important;
    }
}