.teacherProfile img{
    border-radius: 50%;
    width: 150px;
    height: 150px !important;
    display: block;
    margin: 0px auto;
    /* height: 35vh; */
}
.teacherProfile{
    padding: 5px;
    margin: 5px;
    box-shadow: 2px 2px 5px gray;
    
}
.teacherProfile:hover{
    transform: scale(1.04);
    transition: all 0.5s;
}
.teacherProfile:hover  .teacherProfileImage{
    border: 5px solid rgba(21, 36, 67, 1);;
}
.teacherCardBody{
    position: relative;
}
.tag{
    position: absolute;
    top: 10px;
    background-color: green;
    width: 70px;
    padding: 5px 0;
    /* height: 25px; */
    text-align: center;
    font-weight: bold;
    color: white;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
