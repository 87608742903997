.datenew{
    border: 1px solid black;
    border-radius: 15px;
    padding:0px 10px;
    width: 25%;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nextPagenews{
    display: flex;
    justify-content: space-between;
}
.latestMainHead{
    border-bottom: 2px double black;
    display: inline-block;
}
.para{
    display: inline-block;
}
.newsreadmore{
    text-align: end; 
    text-decoration: none;
}
.lastestNews{
    height: 20vh !important;
}
.latestnews{
    display: flex;
    justify-content: space-between;
    height: 70px;
}
.news_title{
    border: 1px solid black;
    border-radius: 15px;
    padding:0px 10px;
    width: 73%;
}
#mymaq {

    overflow: hidden;
    box-sizing: border-box;
}
#mymaq .dataAni {
    /* display: inline-block; */
    padding-top: 100%;
    animation: marquee 15s linear infinite;
}
#mymaq .dataAni:hover{
    animation-play-state: paused;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(0, -100%); }
}