.active {
    transform: scale(1.05);
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
a:active{
    color: brown;
}