:root{
    --colorone:(#be6257);
}
#mynav{
    background-color: #efefef;
    position: relative;
}
#logo_main img {
    height: 10vh;
    width: 30%;
}
/* #mynavLink li a{
    color:#be6257 ;
    font-size: 18px;
    font-weight: 500;

} */
#myLink li a{
    color:#be6257 ;
    font-size: 18px;
    font-weight: 500;

}
#mynavLink li {
    margin: 5px 10px;
}
.headerimg1{
    width: 70px !important;
    display: inline;
}
.logotext1{
    color: #b5594f;
    display: inline-block;
    position: absolute;
    border-left: 3px solid #be6257;
    margin: 10px 15px;
    padding: 0 10px;
}
.myhover ul{
    display: none;
    border-top: 3px solid #be6257;
    position: absolute;
    top: 100%;
}
.myhover ul li a{
    font-size: 14px !important;
}
.myhover:hover ul{
    display: block;
}
@media screen and (max-width:500px) {
    .logotext1 h5{
        font-size: 16px;
        margin-top: 5px;
    } 
    .headerimg1{
        width: 100px;   
    }
}
@media screen and (max-width:1200px) {
    .logotext1 h5{
        font-size: 14px;
        margin-top: 0px;
    } 
    .headerimg1{
        width: 50px !important;   
    }
}