.dateevent{
    border: 1px solid black;
    border-radius: 15px;
    padding:0px 10px;
    width: 25%;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nextPageevent{
    display: flex;
    justify-content: space-between;
}
.latestMainHead{
    border-bottom: 2px double black;
    display: inline-block;
}
.para{
    display: inline-block;
}
.eventreadmore{
    text-align: end; 
    text-decoration: none;
}
.latestevent{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    height: 70px;
    position: relative;
}
.event_title{
    border: 1px solid black;
    border-radius: 15px;
    padding:0px 10px;
    width: 73%;
}
#eventLatest {
    overflow: hidden;
    box-sizing: border-box;
}
#eventLatest .eventLatest {
    /* display: inline-block; */
    padding-top: 100%;
    animation: marquee 15s linear infinite;
}
#eventLatest .eventLatest:hover{
    animation-play-state: paused;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(0, -100%); }
}