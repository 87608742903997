.glimpsemain{
    /* background-image: url('../../public/Images/logo2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto; */
    border: 5px double #ffc33c;

}
.glimpseTExt{
    float: left;
    /* clear: both; */
}
.glimpseTExt p {
    text-indent: 50px;
    padding: 0 5%;
    text-align: justify;
}
.imageGlimpse img{
    display: block;
    margin: auto;
    margin-bottom: 20px;
    width: 100%;
}
