.latestevent img{
    width: 100%;
}
.gallaryImage{
    position: relative;
    height: 200px;
}
.galleryImageText{
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    margin: auto;
    border: 2px solid gray;
    text-align: center;
    height: 90%;
    background-color: rgba(12, 6, 53, 0.7);
    border-radius: 15px;
    z-index: 99;
    transition: all 0.5s ease;
}
.galleryImageText:hover{
    opacity: 1;
}
.galleryImageText{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
