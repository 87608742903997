.singleDepartment{
    width: 100%;
    margin: auto;
    height: 50vh;
    overflow: hidden;
    position: relative;
}
.bannerText{
    position: absolute;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    /* border: 4px solid red; */
    left: 50%;
    transform: translate(-50%,-50%);
}
.bannerText h2{
    position: absolute;
    font-size: 60px;
    top: 50%;
    /* border: 4px solid red; */
    left: 50%;
    transform: translate(-50%,-50%);
}
.constentImage{
    height: 100%;
}

@media screen and (max-width:425px) {
    .bannerText h2{
        font-size: 24px;
    }
}