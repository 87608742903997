
.marData{
    border: 1px solid black;
    padding: 3px 5px;
    border-radius: 10px;
    height:450px!important ;
    overflow-y: hidden;
}

.marDataevent{
    border: 1px solid black;
    padding: 3px 5px;
    border-radius: 10px;
    height:480px!important ;
    overflow: hidden;
}
.videosection{
    height: 500px !important;
}
.mainTest{
    background-color: #3d3d3d;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video_intro{
    height: 20vh;
}
.testimonial{
    padding: 20px 50px;
    margin: 20px 30px;
    border-radius: 0 70px 0 70px;
    border: 5px solid #ffc33c;
    border-left: none;
    border-right: none;
    text-align: center;
}
.testimonial .pic{
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    overflow: hidden;
}
.testimonial .pic img{
    width: 100%;
    height: auto;
}
.testimonial .title{
    display: block;
    margin: 0 0 7px 0;
    font-size: 20px;
    font-weight: 600;
    color: #ffc33c;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.testimonial .post{
    display: block;
    font-size: 15px;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.testimonial .description{
    font-size: 16px;
    color: #fff;
    line-height: 20px;
    text-align: justify;
    padding: 20px 0;
}
.principalImage{
    margin: 20px !important;
    text-align: center;
}
.pImage{
    border-radius: 10px;
}
.messagePrincipal{
    margin: 20px 0 !important;
    border: 5px solid #ffc33c;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 20px;
    border-top: none;
    border-bottom: none;
}
.messagePrincipal p{
    text-indent: 70px;
    margin: 20px 10px;
    text-align: justify;
}
#exampleModal{
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.5s;
    /* padding-top: 7%; */
}
@media screen and (max-width:500px) {
    .testimonial{
        padding: 30px 10px;
    }
    .testimonial h3{
        font-size: 14px !important;
    }
    .video_intro{
        height: 500px;
    }
    .heightIntro{
        width: 100%;
        height: 270px;
        margin: auto;
    }
    .marData{
        width: 100%;
        margin: auto;
        padding: 10px;
    }
    .videosection{
        height: auto !important;
    }
    .mainTest {
        background-color: #3d3d3d;
        height: 600px;
        padding-top: 10px;

    }
    .leftsectionprincipal {
        margin-top:10px !important;
    }
}
@media screen and (max-width:768px) and (min-width:501px) {
    .video_intro{
        height: 500px;
    }
    .heightIntro{
        width: 100%;
        height: 270px;
        margin: auto;
    }
    .marData{
        width: 100%;
        margin: auto;
        padding: 10px;
    }
    .mainTest {
        background-color: #3d3d3d;
        height: 500px;
        padding-top: 10px;
    }
    .leftsectionprincipal {
        margin-top:10px !important;
    }
    .videosection{
        height: 1000px !important;
    }
}

@media screen and (max-width:991px) and (min-width:769px) {
    .mainTest {
        background-color: #3d3d3d;
        height: 520px;
    }
    .main_video{
        height: 550px;
    }
    .heightIntro{
        width: 100%;
        height: 550px;
        margin: auto;
    }
    .videosection{
        height: 1000px !important;
    }
    .video_intro{
        height: 80vh;
    }
}
