/* ------------ Control Btn -------------------- */

.control-btn .next,
.control-btn .prev{
position: absolute;
top: 40%;
width: 50px;
height: 50px;
line-height: 50px;
background: #0f3460;
border-radius: 50%;
transition: 0.5s;
z-index: 5;
}
.control-btn .next{
    right: -20px;
}
.control-btn .prev{

    left: -20px;

}
.control-btn .next
,
.control-btn .prev{
    font-size: 14px;
    transition: 0.5s;
    color: #fff;
    border: none;
}
.control-btn .next:hover,
.control-btn .prev:hover{
    background-color: #e94560;
    color: #fff;
    cursor: pointer;
}
.prevImage{
    width: 50%;
    height: 130px;
    margin: auto;
    border-radius: 10px;
    display: block;
}
.prevPrincipalImage{
    padding: 5% 0;
}
.mainPrincipal{
    width: 90% !important;
    margin: auto;
    border: 1px solid gray !important;
    border-radius: 10px;
    height: 100%;
}
#colHeight{
    height: 290px !important;
}
@media screen and (max-width:500px) {
    .mainPrincipal{
        width: 80% !important;
        margin: auto;
        border: 1px solid gray !important;
        border-radius: 10px;
        height: 100%;
    }
}