#signinback{
    background-image: url('../../public/Images/slider2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.imagefrontsignin{
    background-color: rgba(0, 0, 0, 0.7);

}
#formsignin{
    border: 5px solid white;
    border-radius: 10px; 
    margin: 5% 0;
}