.cardselfdiv{
    /* border: 1px solid gray; */
    border-radius: 15px;
    margin: 5px;
    padding: 5px;
    height: 300px;
}

.cardselfimg img{
    width: 100%;
    height: 170px;
    border-radius: 10px;

}
.cardselfdiv{
    color: #be6257;
    text-decoration: none;
    box-shadow: 2px 2px 5px gray;
    
}
.cardselfdiv:hover{
    transform: scale(1.04);
    transition: all 0.5s;
}
.departlink:hover{
    color: #da9d97;
    
}